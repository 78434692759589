import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { OrdersComponent } from './orders/orders.component';
import { HttpClientModule } from '@angular/common/http';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ProfileComponent } from './profile/profile.component';
import { ErrorComponent } from './error/error.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FeatherModule } from 'angular-feather';
import { ArrowLeft } from 'angular-feather/icons';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Select some icons (use an object, not an array)
const icons = {
  ArrowLeft
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrdersComponent,
    ProfileComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ZXingScannerModule,
    NgbModule,
    FeatherModule.pick(icons),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports:[
    FeatherModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
