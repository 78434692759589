
<div class="container-fluid p-0 montserrat  " >
  
  <div  class="row m-0 " (click)="goOrders()">
    <div class="col">
      <a  class="back-btn btn m-3  rounded border-dark text-black">
        <i-feather  color="black" name="arrow-left"></i-feather>
      </a>
    </div>
  </div>

  
<div class="row m-0">
<div class="col ">
  <div class="mx-auto" style="margin-top: 30px;">
    <img class="rounded-circle" [src]="user.profileImage" height="200" width="200" alt="">
    </div>
</div>
</div>
    
     <div class="mx-auto " style="margin-top: 15px;"> 
    <h4 class="">{{user.name}} {{user.lastName}}</h4>
    </div>
    
    <div class="mx-auto ">
      <p style="color: grey;">{{user.department}}</p>
    </div>
    
    
    <div class="mx-auto" style="margin-top: 20px;"> <h4>{{listOrder.length}}</h4></div>
    
    <div class="row m-0" >
      <div class="col text-center">
        <small class="text-secondary mx-auto">Scanned Orders</small>
      </div>
    </div>
    
    
    
    
      
    <div class="informationUser " style="margin-top:40px ;">
      <button (click)="logout()"  type="button" class="btn btn-dark ">Logout</button>
    </div>
    

 </div>