import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { User } from '../interfaces/user';
import { Router } from '@angular/router'
import { Order } from '../interfaces/order';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: User
  listOrder:Order [] = []
 

  constructor(private data$: DataService, private router:Router) { 
   
    if(!this.data$.userIsLoggedIn()){
      console.log("user is not log in")
      this.router.navigate([''])
    }

    this.user = this.data$.getUser()
    this.listOrder = this.data$.getOrdersByUser(this.user.id)

  }

  ngOnInit(): void {
    
  }


  logout(){
    localStorage.removeItem("user")
    this.router.navigate([''])
  }

  goOrders(){
    console.log(">>>goorders")
    this.router.navigate(['orders'])
  }


}
