import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  // navParams: NavigationType;
  // errorMessage: String;
  status: String
  textStatus: String
  constructor(private activateRoute:ActivatedRoute) {
    // this.navParams = data;
    // this.errorMessage = this.navParams.status == 509 ? this.navParams.error : "Something went wrong!"
    this.activateRoute
    .queryParams
    .subscribe(params => {
      this.status = params['status']
      this.textStatus = params['textstatus']
    });

    console.log("Error Status en Page-Error: ", this.status)
    console.log("Error textStatus en Page-Error: ", this.textStatus)

   }

  ngOnInit(): void {
  }



}
