import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '../interfaces/order';
import { User } from '../interfaces/user';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-order',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {

  ordersList: Order[];
  user: User
 

  constructor(private router: Router, private data$: DataService) {
  

    // Check if user has a session
    if(!this.data$.userIsLoggedIn()){
      console.log("user is not log in")
      this.router.navigate([''])
    }else{

      console.log("user is logged in")
      this.user = this.data$.getUser()
      console.log(this.user)
      this.ordersList = this.data$.getOrders().length > 0 ? this.data$.getOrdersByUser(this.user.id) : []
      console.log(this.data$.getOrdersByUser(this.user.id))
      console.log(">>>> ordenesreverse",this.ordersList)
      console.log(this.data$.getOrders().length)
    }

  }

  ngOnInit(): void {}

}
