import { OrdersComponent } from './../orders/orders.component';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Order } from '../interfaces/order';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import {User} from '../interfaces/user';



@Injectable({
  providedIn: 'root',
})
export class DataService {

  baseUrl: String = environment.config.apiUrl;

  constructor(private http: HttpClient) {}

  // Metodos de conexion API
  validateUserSignature(signature: String): Observable<Object> {
    const data = {'signature': signature};
    return this.http.post(`${this.baseUrl}/users/signature`, data).pipe(
      map((res) => res),
      catchError((err) => throwError(err))
    );
  }

  createOrderTracking(orderNumber: number, userId: number): Observable<Object> {
  
    const data = {
      'order_id': orderNumber,
      'user_id': userId,
      'test': orderNumber,
    };
   
    return this.http.post(`${this.baseUrl}/add_scan_tracking`,data).pipe(
      map((res) => res),
      catchError((err) =>  throwError(err))
      
    );

  }

  saveOrder(order: Order){
    console.log(">>> orden ",order)
    let savedOrders = this.getOrders()
    console.log(">>>>saveOrders", savedOrders)

    if(savedOrders === null ){
      savedOrders = []
    }

    savedOrders.push(order)
    this.deleteExpired()

    return localStorage.setItem('orders', JSON.stringify(savedOrders))
  }


  deleteExpired(){
    const savedOrders = this.getOrders()
    if (savedOrders) {
      
      for (let order of savedOrders) {

        if (moment(order.expirationDate).format('yyyy-MM-DD') <= moment().format('yyyy-MM-DD')) {
          savedOrders.splice(savedOrders.indexOf(order, 1));
        }
      }
      localStorage.setItem('orders', JSON.stringify(savedOrders))
  }
}



  getOrders():Order[]{
    return JSON.parse(localStorage.getItem('orders'));
  }

  // Devuelvo el usuario del localstorage
  getUser():User {
    return JSON.parse(localStorage.getItem('user'));
  }

  userIsLoggedIn() {
    let user = JSON.parse(localStorage.getItem('user'))
    return user !== null;
  }

  getUserSignature():String{
    let user = JSON.parse(localStorage.getItem("user"))
    return user.signature
  }

  getUserId():number{
    let user = JSON.parse(localStorage.getItem("user"))
    return user.id
  }

  //user_id
  getOrdersByUser(userId:number):Order[]{
    let orders = JSON.parse(localStorage.getItem('orders'))
    return orders.filter(order => order.userId === userId)
  }

  getUrl(): String{
    return this.baseUrl
  }




}
