<!-- Header -->
  <div class="container-fluid ">
    <div class="row px-3 py-4">
      <div class="col-9 pl-0">
        <h4 class="montserrat">Welcome Back</h4>
        <h4 class="montserrat font-800">{{user.name}}</h4>
      </div>
      <div class="col-3 p-0 text-right">
        <img routerLink="/profile"  [src]="user.profileImage" alt="" class="rounded-circle">
      </div>
    </div>
  </div>

<div class="container-fluid montserrat">
<!-- Orders list empty -->
<div *ngIf="ordersList.length === 0" class="empty-orders">
  <div class="row">
    <div class="col text-center">
      <img style="max-width: 50%;" src="../../assets/open-doodles-coffee.png" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <h5 *ngIf="ordersList.length === 0"  class="py-3">Looks like the begining <br> of a new day</h5>
      <a routerLink="/" class="mt-4 btn btn-dark btn-lg font-800">Start Scanning</a>
    </div>
  </div>
</div>


<!-- Orders list -->
<div *ngIf="ordersList.length > 0">
  <div class="card"  *ngFor="let order of ordersList.reverse()">
    <div class="card-horizontal">
        <div class="img-square-wrapper">
            <img class="" src="http://via.placeholder.com/70x70" alt="Card image cap">
        </div>
        <div class="card-body">
            <p class="card-title">{{order.status}}</p>
            <p class="card-text">{{order.orderNumber}}</p>
            <p>Departament</p>
        </div>
    </div>
  </div>
</div>
</div>


<!-- Open scanner-->
<a *ngIf="ordersList.length > 0" class="floating-button" routerLink="/">
  <!--
  <svg  aria-hidden="true" width="30" height="30" focusable="false" data-prefix="fas" data-icon="qrcode" class="svg-inline--fa fa-qrcode fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"></path></svg>
  -->
  <svg  aria-hidden="true" width="30" height="30" focusable="false" data-prefix="fas" data-icon="qrcode" class="svg-inline--fa fa-qrcode fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"></path></svg>

</a>



