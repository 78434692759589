import { DataService } from '../services/data.service';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Order } from '../interfaces/order';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HomeComponent implements OnInit {

  scanRead: boolean = true;

  // Props
  user: User;
  // use listOrder and currentUser for get orders by User
  //listOrder:Order [] = []
  //currentUser: User;
  isLoggedIn: boolean = false;
  newOrder: Order;
  showNavBar: boolean = false;
  error: any
  toast: boolean = false
  ignoreScan: boolean = true
  errorToast: String


  // Scanner config
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  allowedFormats = [ BarcodeFormat.QR_CODE, BarcodeFormat.EAN_13, BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX ];
  hasDevices: boolean;
  scannerEnabled: boolean;
  activeScan: boolean = true;

  constructor(
    private data$: DataService,
    private router: Router
  ) {
      this.isLoggedIn = this.data$.userIsLoggedIn();
      //this.currentUser = this.data$.getUser()
      // this.listOrder = this.data$.getOrdersByUser(this.currentUser.id)
  }

  ngOnInit(): void {
  }

  // The scanning result

  
  onCodeResult(resultString: string) {
   
    // Change in toast to false 
    if(this.activeScan){

    // Check we got a result from scan read
    if (resultString.length === 0) {
      return
    }

      // Switch off camera view
      this.scanRead = false;

      if (this.isLoggedIn) {
        // If user is logged in we send value to api as order number

        // Exit error if it is not a number
        if(isNaN(parseInt(resultString))){
          return
        }


         const orderNumber = parseInt(resultString)
         const userId = this.data$.getUserId()

        // Next day
         let expiration = new Date()
         expiration.setDate(expiration.getDate() + 1)
         
        // Send request 
          this.data$.createOrderTracking(orderNumber, userId).subscribe(
          (data:any) => {
            //Order Object for save in localstorage
            const order: Order = {
              orderNumber: data.order_id,
              createdAt: data.created_at,
              deliveryDate: data.delivery_date,
              expirationDate: expiration,
              status: data.status,
              userId: this.data$.getUser().id
            }
            //save order localstorage
            this.data$.saveOrder(order)
            this.router.navigate(['/orders'])}
          ,

           (err) => {
           // Si recibo un error !activeScan toast==true  
            this.errorToast = err.error.error
            console.log("err trancking", err)
            this.activeScan = false
            this.toast = true
           });


      } else {

        this.data$.validateUserSignature(resultString).subscribe(
          (data:any) => {

            let  baseNameImage: String = ""
            // fix error the method toLoweCase does not apply "null"
           
            baseNameImage = data.name + data.lastname
            baseNameImage = baseNameImage.toLowerCase().replace(" ", "")
            

            let URL = this.data$.getUrl() 
            // Get only needed user data
            this.user = {
              id: data.id,
              name: data.name,
              lastName: data.lastname,
              profileImage: `${URL}/assets/images/team/${baseNameImage}.jpg`,
              signature: data.signature,
              email: data.email,
              department: data.department
            }

            // Save to local
            localStorage.setItem('user', JSON.stringify(this.user));

            // Create local sotrage object for order if null
            if(this.data$.getOrders() === null){
              localStorage.setItem('orders', JSON.stringify([]))
            }

            // Redirect to orders view
            this.router.navigate(['orders']);
          },
          (err) => { 
             // Si recibo un error !activeScan toast==true  
             console.log("err Login User", err)
             this.errorToast = err.error.error
             this.activeScan = false
             this.toast = true

          }) 
      }

    // end activeScan
    }


    }


    //btn Close Toast error
    closeToast(){
     this.toast = false
     this.scanRead = true
     this.activeScan = true
     this.router.navigate([''])
    }


}
