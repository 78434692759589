
<div  class="container-fluid vh-100 p-0" [ngClass]="isLoggedIn ? 'after-login' : 'before-login'">


  <!-- Back button -->
  <div *ngIf="isLoggedIn" class="row m-0">
    <div *ngIf="!toast" class="col">
      <a routerLink="orders" class="back-btn btn m-3 position-absolute rounded border-white text-white">
        <i-feather  name="arrow-left"></i-feather>
      </a>
    </div>
  </div>


  <!-- Camera view disablecam => *ngIf="scanRead" -->
  <div class="camera-view">
    <div class="position-absolute m-0 camera-container" >
      <zxing-scanner [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']" class="camera" #scanner start="true" (scanSuccess)="onCodeResult($event)" previewFitMode="cover" ></zxing-scanner>
    </div>
    <div *ngIf="!toast" class="position-absolute m-0 focus-container">
      <div class="position-absolute focusglass"></div>
    </div>


    <!-- Show info to user before login -->
    <div *ngIf="!isLoggedIn" class="position-absolute py-5 bottom-card">
      <div  class="row m-0 " >
        <div class="col text-center">
          <img class="qr-icon" src="../../assets/qricon.png" alt="">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h5 class="montserrat w-100 text-center font-800">Scan your Employee Card</h5>
        </div>
      </div>
    </div>

    <!-- Show tip to user to scan barcode -->
    <div *ngIf="isLoggedIn && !toast" class="position-absolute py-4 bottom-card">
      <div  class="row px-5 m-0" >
        <div class="col-4 text-center">
          <img class="w-100" src="../../assets/barcode-approve.png" alt="">
        </div>
        <div class="col-8 montserrat">
          <h5 class="montserrat font-800">Scan a New Order</h5>
          <p >You will find the code at the upper right side of the internal order invoice</p>
        </div>
      </div>
    </div>
    
  </div>


      <!-- Toast Error  -->
      <div *ngIf="toast" class=" d-flex justify-content-center align-items-center vh-100 toast-container ">
       <div class="toast d-flex  flex-column justify-content-center align-items-center pt-5 pb-5  mw-85" role="alert" aria-live="assertive" aria-atomic="true">  
  
        <div class="toast-body  text-center ">
          {{errorToast}}
        </div>
    
        <div>
        <button (click)="closeToast()" type="button" class="btn btn-dark mt-2">Exit</button>        
        </div>
          
        </div>
      </div>   

</div>
